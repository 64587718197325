import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Img from 'gatsby-image'
import Iconsbar from "../components/iconsbar/navigationLine";
import './category.css'



const ArtistPage = ({data,title}) => (
 
  <div>
    <SEO title="Artist Page" />
    {/* */}
   
    <Iconsbar />
    <Container fluid className="catBg  ">           
      <Container>
        <div >
          <h2 className="text-center text-white pt-2 pb-5">ABOUT ARTIST</h2>
          <Row>
            <Col className="mainCard text-center">
            <ul type="none" className="p-0">
              {data.allMarkdownRemark.edges.map(({node}) => (
                  <Col lg={3} className="catCard ">
                  <li >
                  <Img fluid={node.frontmatter.featureImage.childImageSharp.fluid} />
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  
                  </li>
                  </Col>
                ))}
              </ul>            
            </Col>
          </Row>
          
        </div>      
      </Container>
      
    </Container>  
  </div>
    
  
)

export default ArtistPage



export const ArtistQuery = graphql`

  query{
    site{
      siteMetadata{
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/artist/"}},
                      sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          id
          frontmatter {
            title
            artist
            featureImage{
              childImageSharp{
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }  
`